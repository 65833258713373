const flFiltersInit = () => {
  // const { getData } = window.phoenix.utils;
  // const lotties = document.querySelectorAll('.lottie');
  // if (lotties.length) {
  //   lotties.forEach(item => {
  //     const options = getData(item, 'options');
  //     window.bodymovin.loadAnimation({
  //       container: item,
  //       path: '../img/animated-icons/warning-light.json',
  //       renderer: 'svg',
  //       loop: true,
  //       autoplay: true,
  //       name: 'Hello World',
  //       ...options
  //     });
  //   });
  // }
  console.log('yes');
  document.querySelectorAll('.filter .activable').forEach(filter =>
    filter.addEventListener('click', () => {
      document
        .querySelectorAll('.activable')
        .forEach(activable => activable.classList.remove('active'));
      filter.classList.toggle('active');
    })
  );
  // function filteringSearch() {
  //   console.log("filteringSearch");
  // }
};
console.log('yess');

export default flFiltersInit;
