import { echartSetOption } from './charts/echarts/echarts-utils';

const StatistiquesGraphiqueCommandesInit = () => {
  const { getColor, getData, rgbaColor } = window.phoenix.utils;
  const $chartEl = document.querySelector(
    '.statistiques-graphique-commandes-chart'
  );

  const data = [
    {
      value: 260,
      name: 'Aix-en-Provence',
      itemStyle: {
        color: getColor('primary')
      }
    },
    {
      value: 96,
      name: 'Île-de-France',
      itemStyle: {
        color: rgbaColor(getColor('primary'), 0.8)
      }
    },
    {
      value: 31,
      name: 'Rhône-Alpes',
      itemStyle: {
        color: rgbaColor(getColor('primary'), 0.6)
      }
    },
    {
      value: 17,
      name: 'Atlantique',
      itemStyle: {
        color: rgbaColor(getColor('primary'), 0.4)
      }
    },
    {
      value: 12,
      name: 'Nice',
      itemStyle: {
        color: rgbaColor(getColor('primary'), 0.2)
      }
    }
    // {
    //   value: 682,
    //   name: 'Classic addition',
    //   itemStyle: {
    //     color: rgbaColor(getColor('warning'), 0.8)
    //   }
    // },
    // {
    //   value: 471,
    //   name: 'Premium',
    //   itemStyle: {
    //     color: getColor('success')
    //   }
    // },
    // {
    //   value: 524,
    //   name: 'Platinum',
    //   itemStyle: {
    //     color: getColor('info')
    //   }
    // },
    // {
    //   value: 200,
    //   name: 'Platinum Pro',
    //   itemStyle: {
    //     color: rgbaColor(getColor('primary'), 0.5)
    //   }
    // }
  ];

  if ($chartEl) {
    const userOptions = getData($chartEl, 'echarts');
    const chart = window.echarts.init($chartEl);
    const getDefaultOptions = () => ({
      // title: [
      //   {
      //     text: 'Pie Label Align Chart',
      //     left: 'center',
      //     textStyle: {
      //       color: getColor('gray-600')
      //     }
      //   },
      //   {
      //     subtext: 'alignTo: "labelLine"',
      //     left: '50%',
      //     top: '85%',
      //     textAlign: 'center',
      //     subtextStyle: {
      //       color: getColor('gray-700')
      //     }
      //   }
      // ],

      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none'
        }
      },

      series: [
        {
          type: 'pie',
          radius: window.innerWidth < 530 ? '45%' : '60%',
          center: ['50%', '50%'],
          data,
          label: {
            position: 'outer',
            alignTo: 'edge',
            // alignTo: 'labelLine',
            margin: 0,
            color: getColor('gray-700')
          },
          left: '0%',
          right: '0%',
          top: 0,
          bottom: 0
        }
      ]
    });

    const responsiveOptions = {
      xs: {
        series: [
          {
            radius: '45%'
          }
        ]
      },
      sm: {
        series: [
          {
            radius: '60%'
          }
        ]
      }
    };

    echartSetOption(chart, userOptions, getDefaultOptions, responsiveOptions);
  }
};

export default StatistiquesGraphiqueCommandesInit;
